import Swiper, { Navigation, A11y} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import VideoWorker from 'video-worker';
import { LitElement } from 'lit';

Swiper.use([Navigation, A11y]);

class ServiceHero extends LitElement {

	constructor() {
		super();
		this.slider = this.querySelector(".service-hero-slider");
		this.videoLink = this.querySelector(".service-hero-video-link");
		this.iframeWrap = document.querySelector(".iframe-wrap");
		this.swiperController = false;
		this.backgroundVideo = false;
		this.resizeObserver = null;
	}

	connectedCallback() {
		super.connectedCallback();


		if (this.querySelector('.hero-services-wrap')) {

			this.resize();
			this.resizeObserver = new ResizeObserver(() => {
				this.resize();
			})
		}

		this.slider = this.querySelector(".service-hero-slider");
		this.videoLink = this.querySelector(".service-hero-video-link");
		this.iframeWrap = document.querySelector(".iframe-wrap");
		if (this.slider) {
			this.run();
		}
		if (this.videoLink) {
			this.getBackgroundVideo();
		}
		this.classList.add("s-section");
		this.classList.add("alignfull");

	}

	firstUpdated() {

	}

	getBackgroundVideo() {
		if(!this.videoLink) {
			this.videoLink = this.querySelector(".service-hero-video-link");
		}
		this.backgroundVideo = this.videoLink.innerHTML;
		this.backgroundVideo = new VideoWorker(this.backgroundVideo, {
			loop: true,
			mute: true,
			autoplay: true,
			accessibilityHidden: true,
			startTime: 0,
			volume: 0,
			showControls: false,
		});
		if (this.backgroundVideo.isValid()) {
			this.backgroundVideo.getVideo((video) => {
				this.iframeWrap.appendChild(video);
			});
		}

		this.backgroundVideo.on('ready', (event) => {
			console.log('ready');
			//this.backgroundVideo.play();
		});

	}

	resize() {
		const overflowEl = this.querySelector(".hero-services-wrap");
		const { height } = overflowEl.getBoundingClientRect();

		this.style.paddingBottom = (height / 2) + "px";
		this.style.marginBottom = (height / 2) + "px";

		this.setAttribute('style', `padding-bottom: ${height / 2}px !important; margin-bottom: ${height / 2}px;`);

	}

	createRenderRoot() {
		return this;
	}

	run() {
		this.swiperController = new Swiper(this.slider, {
			slidesPerView: 1.2,
			spaceBetween: 10,
			slidesPerGroup: 1,
			loop: false,
			mobileFirst: true,
			breakpoints: {
				678: {
					slidesPerView: 2.2,
					spaceBetween: 30
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 40
				},
			},
			navigation: {
				nextEl: '.service-hero-slider-next',
				prevEl: '.service-hero-slider-prev',
			},
			a11y: {
				prevSlideMessage: 'Previous slide',
				nextSlideMessage: 'Next slide',
				notificationClass: 'swiper-a11y-notification',
				prevSlideDisabledClass: 'swiper-a11y-prev-slide-disabled',
				nextSlideDisabledClass: 'swiper-a11y-next-slide-disabled',
			},
		});
	}
}



export default ServiceHero;
