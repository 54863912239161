import ServiceHero from './service-hero/service-hero';
import NewsSlider from './news-slider/news-slider';
import TestimonialSlider from './testimonial-slider/testimonial-slider';




if (window.wp) {
	const { select, subscribe } = wp.data;
	const closeListener = subscribe( () => {
		const isReady = select( 'core/editor' ).__unstableIsEditorReady();
		if ( ! isReady ) {
			// Editor not ready.
			return;
		}
		// Close the listener as soon as we know we are ready to avoid an infinite loop.
		closeListener();
		// Your code is placed after this comment, once the editor is ready.
		window.customElements.define( 'service-hero', ServiceHero );
		window.customElements.define( 'news-slider', NewsSlider );
		window.customElements.define( 'testimonial-slider', TestimonialSlider );
	});
} else {
	window.customElements.define( 'service-hero', ServiceHero );
	window.customElements.define( 'news-slider', NewsSlider );
	window.customElements.define( 'testimonial-slider', TestimonialSlider );
}
