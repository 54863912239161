import Swiper, {A11y, Pagination} from "swiper";
import {LitElement} from "lit";


Swiper.use([A11y, Pagination]);
import "swiper/css/pagination";

class TestimonialSlider extends LitElement {

	constructor() {
		super();
		this.slider = this.querySelector(".swiper");
		this.pagination = this.querySelector(".swiper-pagination");
		this.swiperController = false;
	}

	connectedCallback() {
		super.connectedCallback();

		this.slider = this.querySelector(".swiper");
		this.pagination = this.querySelector(".swiper-pagination");

		if (this.slider) {
			this.run();
		}
	}

	createRenderRoot() {
		return this;
	}

	run() {
		this.swiperController = new Swiper(this.slider, {
			slidesPerView: 1,
			spaceBetween: 30,
			slidesPerGroup: 1,
			loop: false,
			pagination: {
				el: this.pagination,
				type: "bullets",
			},
			a11y: {
				prevSlideMessage: 'Previous Testimonial slide',
				nextSlideMessage: 'Next Testimonial slide',
				notificationClass: 'swiper-a11y-notification',
				prevSlideDisabledClass: 'swiper-a11y-prev-slide-disabled',
				nextSlideDisabledClass: 'swiper-a11y-next-slide-disabled',
			},
		});
	}
}

export default TestimonialSlider;
