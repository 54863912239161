import Swiper, {A11y, Navigation} from "swiper";
import {LitElement} from "lit";


Swiper.use([Navigation, A11y]);

class NewsSlider extends LitElement {

	constructor() {
		super();
		this.slider = this.querySelector(".swiper");
		this.nextButton = this.querySelector(".swiper-button-next");
		this.prevButton = this.querySelector(".swiper-button-prev");
		this.swiperController = false;
	}

	connectedCallback() {
		super.connectedCallback();

		this.slider = this.querySelector(".swiper");
		this.nextButton = this.querySelector(".swiper-button-next");
		this.prevButton = this.querySelector(".swiper-button-prev");

		console.log("hey from slider");
		if (this.slider) {
			this.run();
		}
	}

	createRenderRoot() {
		return this;
	}

	run() {
		this.swiperController = new Swiper(this.slider, {
			slidesPerView: 1.2,
			spaceBetween: 10,
			slidesPerGroup: 1,
			loop: false,
			mobileFirst: true,
			breakpoints: {
				678: {
					slidesPerView: 2.2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: this.nextButton,
				prevEl: this.prevButton,
			},
			a11y: {
				prevSlideMessage: 'Previous slide',
				nextSlideMessage: 'Next slide',
				notificationClass: 'swiper-a11y-notification',
				prevSlideDisabledClass: 'swiper-a11y-prev-slide-disabled',
				nextSlideDisabledClass: 'swiper-a11y-next-slide-disabled',
			},
		});
	}
}

export default NewsSlider;
